<template>
  <span :style="{ background: `rgba(139, 195, 74, ${this.opacity})` }">{{
    value
  }}</span>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number],
    },
  },
  data: () => ({ opacity: 0.0, intervalId: null }),
  watch: {
    value() {
      if (this.intervalId) clearInterval(this.intervalId);

      this.opacity = 1.0;
      this.intervalId = setInterval(() => {
        this.opacity -= 0.1;
        if (this.opacity === 0.0) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      }, 50);
    },
  },
};
</script>
